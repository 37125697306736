import React from 'react';
import { SolutionItems } from '../Solutions/Solutions';
import { ReactComponent as Convex } from '../SVG/convex.svg';

function SolutionsSection() {
  return (
    <section
      style={{ background: "#fafafa" }}
      className="SolutionsSection">
      <Convex className="convex top"/>
      <div className="content">
        <h1>A full suite of tools</h1>
        <h2>Use just one tool or the entire suite</h2>
        <SolutionItems/>
      </div>
      <Convex className="convex bottom"/>
    </section>
  );
}

export default SolutionsSection;
