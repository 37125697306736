import React from 'react';
import Cookies from 'js-cookie';
import Analytics from "../analytics";
import './Contact.css';

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      company: "",
      email: "",
      message: "",
      status: null
    }
  }

  componentDidMount() {
    // Get cookies
    let firstname = Cookies.get("firstname")
    let lastname = Cookies.get("lastname")
    let email = Cookies.get("email")
    let company = Cookies.get("company")

    // Update form from cookies
    this.setState({
      firstname: (firstname) ? firstname : "",
      lastname: (lastname) ? lastname : "",
      email: (email) ? email : "",
      company: (company) ? company : ""
    });
  }

  // Track input changes
  handleChange(event) {
    let state = {};
    state[event.target.name] = event.target.value;
    this.setState(state);
  }

  // Handle form submit
  handleSubmit(event) {
    // Prevent uncontrolled submit
    event.preventDefault();

    // Validate email
    if(!/.+@.+/.test(this.state.email)) {
      return alert("Please enter a valid email");
    }
    // Validate message
    if(this.state.message === "") {
      return alert("Please enter a message");
    }

    // Save form data for 7 days
    const exp = { expires: 7 };
    Cookies.set("firstname", this.state.firstname, exp);
    Cookies.set("lastname", this.state.lastname, exp);
    Cookies.set("email", this.state.email, exp);
    Cookies.set("company", this.state.company, exp);

    // HubSpot parameters
    const portalId = "2098232";
    const formGuid = "ca90ab82-1d3a-4704-a651-5ca94476145c";
    let data = {
      fields: [
        {
          name: "firstname",
          value: this.state.firstname
        },
        {
          name: "lastname",
          value: this.state.lastname
        },
        {
          name: "company",
          value: this.state.company
        },
        {
          name: "email",
          value: this.state.email
        },
        {
          name: "message",
          value: this.state.message
        }
      ],
      submittedAt: Date.now(),
      context: {
        hutk: Cookies.get('hubspotutk'),
        pageName: document.title,
        pageUri: window.location.href
      }
    };
    // Fetch parameters
    let url = "https://api.hsforms.com/submissions/v3/integration/submit/"
      +portalId+"/"+formGuid;
    let options = {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    // Submit form
    fetch(url, options).then(
      (result) => {
        this.setState({ status: true });
        window.scroll({ top: 0, left: 0 });
        // Record conversion
        Analytics.conversion("Contact Us", "Bfe2CKevsLMBEPijlc8C", 4623028);
      },
      (error) => {
        this.setState({ status: false });
        window.scroll({ top: 0, left: 0 });
        console.error(error);
      }
    );
  };

  // Reset status
  clearStatus() {
    this.setState({ status: null });
  }

  // Render something
  render() {
    // Render success message
    if(this.state.status === true) return (
      <div className="status">
        Thank you! We'll be in touch soon.
      </div>
    );

    // Create email link
    let sales_email = "noreply@example.com"
      .replace("noreply", "hello")
      .replace("example", "lotsuite");
    let subject = "?subject=LotSuite%20Question";
    let body = "&body=";
      body += (this.state.firstname === "") ? "" :
        "First Name: \n"+this.state.firstname+"\n\n";
      body += (this.state.lastname === "") ? "" :
        "Last Name: \n"+this.state.lastname+"\n\n";
      body += (this.state.company === "") ? "" :
        "Company Name: \n"+this.state.company+"\n\n";
      body += (this.state.email === "") ? "" :
        "Work Email: \n"+this.state.email+"\n\n";
      body += (this.state.message === "") ? "" :
        "Message: \n"+this.state.message;
    let email_link = (
      <a key="link" href={"mailto:"+sales_email+subject+encodeURI(body)}>
        {sales_email}
      </a>
    );
    // Render error message
    if(this.state.status === false) return (
      <div className="status error">
        Oops! Something went wrong, please <u
        onClick={() => this.clearStatus()}>try again</u> or
        contact {email_link}.
      </div>
    );

    // Render form
    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <label htmlFor="firstname">
          First Name
        </label>
        <input
          name="firstname"
          type="text"
          autoComplete="given-name"
          value={this.state.firstname}
          placeholder="First name"
          onChange={this.handleChange.bind(this)}/>
        <label htmlFor="lastname">
          Last Name
        </label>
        <input
          name="lastname"
          type="text"
          autoComplete="family-name"
          value={this.state.lastname}
          placeholder="Last name"
          onChange={this.handleChange.bind(this)}/>
        <label htmlFor="company">
          Company Name
        </label>
        <input
          name="company"
          type="text"
          autoComplete="organization"
          value={this.state.company}
          placeholder="Company name"
          onChange={this.handleChange.bind(this)}/>
        <label htmlFor="email">
          Work Email *
        </label>
        <input
          name="email"
          type="email"
          required={true}
          autoComplete="email"
          value={this.state.email}
          placeholder="Work email *"
          onChange={this.handleChange.bind(this)}/>
        <label htmlFor="message">
          Message *
        </label>
        <textarea
          name="message"
          type="text"
          required={true}
          autoComplete="off"
          value={this.state.message}
          placeholder="How can we help? *"
          onChange={this.handleChange.bind(this)}/>
        <input
          type="submit"
          value="Submit"/>
      </form>
    );
  }
}

function Contact() {
  return (
    <div className="Contact darkHeader">
      <div className="container">
        <h1>Contact Our Team</h1>
        <ContactForm/>
      </div>
    </div>
  );
}

export default Contact;
