import React from 'react';
import { Link } from "react-router-dom";
import './Features.css';

function Features() {
  return (
    <div className="Features">
      <div className="content">
        <h1>Streamline the Sales Process</h1>
        <h2>Intelligently match customers to salespeople</h2>
        <div className="item">
          <h2>Manage Ups</h2>
          Assign salespeople to customers fairly and transparently
        </div>
        <div className="item new">
          <h2>Send to Finance</h2>
          Send your finished deals to the <Link to="/finance_dispatch">Finance Dispatch</Link> with one click
        </div>
        <div className="item">
          <h2>Auto-Rotation</h2>
          Limit the time a salesperson has to wait at the top of the up list
        </div>
        <div className="showMoreItems">
          <div
            className="button"
            onClick={(e) => e.target.parentNode.classList.add("show")}>
            Show More
          </div>
          <div className="fakeItem"/>
        </div>
        <div className="item new">
          <h2>Time Tracking</h2>
          Time the sales process for each salesperson from start to finish
        </div>
        <div className="item">
          <h2>Round Robin or Flex</h2>
          Keep the same order all day or update after each up
        </div>
        <div className="item new">
          <h2>Send to Wash Rack</h2>
          One click to send a sold car to the <Link to="/wash_rack">Wash Rack</Link> for delivery prep
        </div>
        <div className="item new">
          <h2>Manual Assignment</h2>
          Assign customers to salespeople manually or automatically
        </div>
        <div className="item">
          <h2>Position Alerts</h2>
          Receive notifications when position or status is changed
        </div>
        <div className="item">
          <h2>Paging System</h2>
          Message your whole team or a specific employee
        </div>
        <div className="item">
          <h2>CRM Integration</h2>
          Push customer information from LotSuite into your CRM
        </div>
        <div className="item">
          <h2>Advanced Reporting</h2>
          View in real-time or export by day, week, month, or year
        </div>
        <div className="item">
          <h2>Record Notes</h2>
          Track opportunities and share messages with managers
        </div>
        <div className="item">
          <h2>Manager Override</h2>
          Change a salesperson's position on the list manually
        </div>
        <div className="item">
          <h2>Salesperson Lock</h2>
          Managers can restrict a specific salesperson from taking ups
        </div>
        <div className="item">
          <h2>Joint Position</h2>
          Assign two salespeople to the same position or customer
        </div>
        <div className="item">
          <h2>100% Customizable</h2>
          Customize your workflows, buttons, integrations, and more
        </div>
        <div className="item">
          <h2>Works Where You Do</h2>
          Access LotSuite from your desktop, smartphone, or tablet
        </div>
        <Link
          to="/features"
          className="item">
          <div className="center">
            <h2>View More</h2>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Features;
