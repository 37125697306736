// Global variables
let gtag;

// Define production and development tokens
// Google Analytics (Universal Analytics)
const gaProd = 'UA-64725686-8';
const gaDev = 'UA-64725686-9';
// Google Analytics 4
const ga4Prod = 'G-BZYT4723XF';
const ga4Dev = 'G-CZMCSDVF9H';
// Google AdWords
const awProd = 'AW-702894584';
const awDev = null;
// LinkedIn
const liProd = '3329004';
const liDev = null;

// Check if production or development
let env_check = process.env.NODE_ENV === 'production' &&
  /^(www\.)?lotsuite\.com/i.test(window.location.hostname);

const Analytics = {
  // Initialize analytics
  initialize: () => {
    // Google Analytics
    window.dataLayer = window.dataLayer || [];
    gtag = function(){ window.dataLayer.push(arguments) }
    gtag('js', new Date());
    const gaProps = {
      send_page_view: false
    };
    gtag('config', (env_check) ? gaProd : gaDev, gaProps);  // Universal Analytics
    gtag('config', (env_check) ? ga4Prod : ga4Dev, gaProps);  // Google Analytics 4
    gtag('config', (env_check) ? awProd : awDev);  // AdWords

    // LinkedIn
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push((env_check) ? liProd : liDev);
    (function(l) {
    if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
    window.lintrk.q=[]}
    var s = document.getElementsByTagName("script")[0];
    var b = document.createElement("script");
    b.type = "text/javascript";b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);})();
  },

  // Track conversions
  // Conversion IDs are provided by AdWords and LinkedIn
  conversion: (event_label, aw_conversion_id, li_conversion_id) => {
    // Google Analytics
    gtag('event', 'conversion', {  // Send to AdWords
      send_to: (env_check) ? awProd : awDev + '/' + aw_conversion_id
    });
    gtag('event', 'conversion', {  // Send to all initialized analytics
      event_category: "form",
      event_label: event_label
    });

    // LinkedIn
    window.lintrk('track', { conversion_id: li_conversion_id });
  },

  // Track events
  event: (name, props) => {
    // Google Analytics
    gtag('event', name, props);
  },

  // Track page views
  pageview: (path) => {
    // Google Analytics
    gtag('set', {  // Set current page info
      page_title: document.title,
      page_path: path
    });
    gtag('event', 'page_view');  // Send page view event
    // Hubspot
    var _hsq = window._hsq = window._hsq || [];
      _hsq.push(['setPath', path]);
      _hsq.push(['trackPageView']);

    // LinkedIn
    window.lintrk();
  }
};

export default Analytics;
