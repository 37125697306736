import React from 'react';
import './Logos.css';
import DealerSocket from './logos/DealerSocket.png';
import ELEAD from './logos/ELEAD.png';
import VinSolutions from './logos/VinSolutions.png';

function Logos() {
  return (
    <section className="Logos">
      <div className="content">
        <h1>Works with all major CRMs</h1>
        <h2>LotSuite integrates with these and most other CRMs</h2>
        <div className="logo">
          <img
            alt="DealerSocket"
            src={DealerSocket}/>
        </div>
        <div className="logo">
          <img
            alt="ELEAD"
            src={ELEAD}/>
        </div>
        <div className="logo">
          <img
            alt="VinSolutions"
            src={VinSolutions}/>
        </div>
      </div>
    </section>
  );
}

export default Logos;
