import React from 'react';
import './Background.css';

function Background() {
  return (
    <svg className="Background">
      <pattern
        id="pattern-circles"
        x="0"
        y="0"
        width="40"
        height="40"
        patternUnits="userSpaceOnUse">
        <circle cx="20" cy="20" r="10"/>
      </pattern>
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill="url(#pattern-circles)"/>
    </svg>
  );
}

export default Background;
