import React from 'react';
import { Link } from "react-router-dom";
import Analytics from "../analytics";
import './Footer.css';
import { ReactComponent as LotSuiteIcon } from '../SVG/lotsuite.svg';
import AppStore from './badges/app_store.png';
import PlayStore from './badges/google_play.png';


function Footer() {
  return (
    <div className="Footer">
      <div className="content">
        <div className="about">
          <Link to="/" className="logo">
            <LotSuiteIcon className="icon"/>
            <span className="title">
              LotSuite
            </span>
          </Link>
          <div className="apps">
            <a target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/us/app/lotsuite-by-makin-auto/id1465839854"
              onClick={() => {
                Analytics.event("get_app", {
                  event_category: "link",
                  event_label: "App Store"
                });
              }}>
              <img alt="App Store" src={AppStore}/>
            </a>
            <a target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.lotsuite&hl=en_US"
              onClick={() => {
                Analytics.event("get_app", {
                  event_category: "link",
                  event_label: "Google Play Store"
                });
              }}>
              <img alt="Google Play Store" src={PlayStore}/>
            </a>
          </div>
        </div>

        <div className="links">
          <div className="column">
            <h1>Products</h1>
            <Link to="/features">Features</Link>
            <Link to="/solutions">Solutions</Link>
            <Link to="/up_system">Up System</Link>
            <Link to="/wash_rack">Wash Rack</Link>
            <Link to="/valet_dispatch">Valet Dispatch</Link>
            <Link to="/finance_dispatch">Finance Dispatch</Link>
            <Link to="/ServiceDriveDispatch">Service Drive Dispatch</Link>
          </div>
          <div className="column">
            <h1>Resources</h1>
            <a target="_blank"
              rel="noopener noreferrer"
              href="https://app.lotsuite.com"
              onClick={() => {
                Analytics.event("log_in", {
                  event_category: "link",
                  event_label: "Log In (Footer)"
                });
              }}>Log In</a>
            <a target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/us/app/lotsuite-by-makin-auto/id1465839854"
              onClick={() => {
                Analytics.event("get_app", {
                  event_category: "link",
                  event_label: "iOS App"
                });
              }}>iOS App</a>
            <a target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.lotsuite&hl=en_US"
              onClick={() => {
                Analytics.event("get_app", {
                  event_category: "link",
                  event_label: "Android App"
                });
              }}>Android App</a>
            <a target="_blank"
              rel="noopener noreferrer"
              href="https://dev.lotsuite.com"
              onClick={() => {
                Analytics.event("exit", {
                  event_category: "link",
                  event_label: "Developer Console"
                });
              }}>Developer Console</a>
          </div>
          <div className="column">
            <h1>Company</h1>
            <Link to="/contact">Contact</Link>
            <a target="_blank"
              rel="noopener noreferrer"
              href="https://www.makinauto.com"
              onClick={() => {
                Analytics.event("exit", {
                  event_category: "link",
                  event_label: "Makin Auto"
                });
              }}>Makin Auto</a>
            <a target="_blank"
              rel="noopener noreferrer"
              href="https://legal.makinauto.com/lotsuite/terms_and_conditions.pdf"
              onClick={() => {
                Analytics.event("exit", {
                  event_category: "link",
                  event_label: "Legal"
                });
              }}>
              Legal</a>
          </div>
        </div>

        <div className="copyright">
          &copy; Makin Automotive Software, LLC
        </div>
      </div>
    </div>
  );
}

export default Footer;
