import React from 'react';
import { Link } from "react-router-dom";
import Start from '../Home/Start';
import './Solutions.css';

export function SolutionItems() {
  return (
    <div className="SolutionItems">
      <Link to="/up_system"
        id="up_system"
        className="item">
        <h2>Up System</h2>
        <p>Assign ups without conflict</p>
        <p>Create leads from your phone</p>
        <p>Track sales activity in real-time</p>
        <span className="learn">Learn More</span>
      </Link>
      <Link to="/valet_dispatch"
        id="valet_dispatch"
        className="item">
        <h2>Valet Dispatch</h2>
        <p>Expedite service vehicle pickup</p>
        <p>Increase service drive capacity</p>
        <p>Find underperforming valets</p>
        <span className="learn">Learn More</span>
      </Link>
      <Link to="/finance_dispatch"
        id="finance_dispatch"
        className="item">
        <h2>Finance Dispatch</h2>
        <p>Assign finance deals fairly</p>
        <p>Track sales from start to finish</p>
        <p>Keep finance managers happy</p>
        <span className="learn">Learn More</span>
      </Link>
      <Link to="/wash_rack"
        id="wash_rack"
        className="item">
        <h2>Wash Rack</h2>
        <p>Get alert when vehicle is ready</p>
        <p>Check on a car from anywhere</p>
        <p>Warnings if car needs attention</p>
        <span className="learn">Learn More</span>
      </Link>
      <Link to="/service_drive_dispatch"
        id="service_drive_dispatch"
        className="item">
        <h2>Service Drive Dispatch</h2>
        <p>Help service customers faster</p>
        <p>Monitor service drive activity</p>
        <p>Prevent service writer conflict</p>
        <span className="learn">Learn More</span>
      </Link>
      <Link
        to="/features"
        className="item link">
        <div className="center">
          <h2>Explore Features</h2>
        </div>
      </Link>
    </div>
  );
}

function Solutions() {
  return (
    <div className="Solutions darkHeader">
      <div className="content">
        <h1>Explore Solutions</h1>
        <h2>Supercharge your entire dealership</h2>
        <SolutionItems/>
      </div>
      <Start/>
    </div>
  );
}

export default Solutions;
