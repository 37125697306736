import React from 'react';
import Background from '../Home/Background';
import Features from './Features'
import Start from '../Home/Start';
import './UpSystem.css';
import '../Print.css';


function Hero() {
  return (
    <div className="Hero">
      <Background/>
      <div className="content">
        <h1>Up System</h1>
        <h3>Assign customers to your sales team</h3>
      </div>
    </div>
  );
}

function UpSystem() {
  return (
    <div className="DetailsPage UpSystem">
      <Hero/>
      <Features/>
      <Start/>
    </div>
  );
}

export default UpSystem;
