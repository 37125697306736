import React from 'react';
import { Link } from "react-router-dom";
import Demo from './Demo';
import Cookies from 'js-cookie';
import Analytics from "../analytics";
import Background from './Background';
import './Hero.css';

class HeroForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      firstname: "",
      lastname: "",
      email: "",
      status: null
    }
  }

  // Track input changes
  handleChange(event) {
    let state = {};
    state[event.target.name] = event.target.value;
    this.setState(state);
  }

  // Handle form submit
  handleSubmit(event) {
    // Prevent uncontrolled submit
    event.preventDefault();

    // Validate email
    if(!/.+@.+/.test(this.state.email)) {
      return alert("Please enter a valid email");
    }

    // Save email for 7 days
    Cookies.set("email", this.state.email, { expires: 7 });

    // HubSpot parameters
    const portalId = "2098232";
    const formGuid = "c08c6dd4-d1a3-4115-baf2-d4d6cae96f5f";
    let data = {
      fields: [
        {
          name: "firstname",
          value: this.state.firstname
        },
        {
          name: "lastname",
          value: this.state.lastname
        },
        {
          name: "email",
          value: this.state.email
        }
      ],
      submittedAt: Date.now(),
      context: {
        hutk: Cookies.get('hubspotutk'),
        pageName: document.title,
        pageUri: window.location.href
      }
    };
    // Fetch parameters
    let url = "https://api.hsforms.com/submissions/v3/integration/submit/"
      +portalId+"/"+formGuid;
    let options = {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    // Submit form
    fetch(url, options).then(
      (result) => {
        this.setState({ status: true });
        // Record conversion
        Analytics.conversion("View Demo", "UV6OCIv6vLMBEPijlc8C", 4623036);
      },
      (error) => {
        this.setState({ status: false });
        console.error(error);
      }
    );
  };

  // Return form
  render() {

    // Set form class
    let className = "form " +
      ((!this.state.show) ? "show_button"
        : (this.state.status === true) ? "show_success"
          : (this.state.status === false) ? "show_error" : "");

    // Create email link
    let sales_email = "noreply@example.com"
      .replace("noreply", "hello")
      .replace("example", "lotsuite");
    let subject = "?subject=Interested%20in%20LotSuite";
    let body = (this.state.email === "") ? "" :
      "&body="+encodeURI("Hello, I am interested in LotSuite and my "+
        "email is "+this.state.email);
    let email_link = (
      <a key="link" href={"mailto:"+sales_email+subject+body}>
        {sales_email}
      </a>
    );

    // Return form
    return (
      <div className={className}>
        <div className="status success">
          <h4>Submitted!</h4>
          We will contact you ASAP to schedule your LotSuite demo.
        </div>
        <div className="status error">
          <h4>Oops!</h4>
          Something went wrong, please try again or contact us
          directly at {email_link}
        </div>
        <div className="buttons">
          <button
            href="false"
            className="demo_button"
            onClick={() => {
              // Show form
              this.setState({show: true});
              // Record event
              Analytics.event("form_opened", {
                event_category: "form",
                event_label: "View Demo"
              });
            }}>
            View Demo
          </button>
          <div>
            <Link
              to="/contact"
              onClick={() => {
                Analytics.event("form_opened", {
                  event_category: "form",
                  event_label: "Contact Sales"
                });
              }}>
              Contact Sales
            </Link>
            <b>&rarr;</b>
          </div>
        </div>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <label htmlFor="firstname">
            First Name
          </label>
          <input
            name="firstname"
            type="text"
            autoComplete="given-name"
            value={this.state.firstname}
            placeholder="First name"
            className="name"
            onChange={this.handleChange.bind(this)}/>
          <label htmlFor="lastname">
            Last Name
          </label>
          <input
            name="lastname"
            type="text"
            autoComplete="family-name"
            value={this.state.lastname}
            placeholder="Last name"
            className="name"
            onChange={this.handleChange.bind(this)}/>
          <label htmlFor="email">
            Work Email *
          </label>
          <input
            name="email"
            type="email"
            required={true}
            autoComplete="email"
            value={this.state.email}
            placeholder="Work email *"
            onChange={this.handleChange.bind(this)}/>
          <button>
            View Demo
          </button>
          &nbsp;
        </form>
      </div>
    );
  }
}

function Hero() {
  return (
    <section className="Hero">
      <Background/>
      <div className="content">
        <div className="content_left">
          <div className="h_wrapper">
            <h1>
              A Better Up System
            </h1>
            <h2>
              And so much more...
            </h2>
          </div>
          <h3>
            LotSuite is an entire suite of tools for managing your
            dealership's sales and fixed operations teams.
            <br/><br/>
            Use just one tool or the entire suite.
          </h3>
          <HeroForm/>
        </div>
        <div className="content_right">
          <Demo/>
        </div>
      </div>
    </section>
  );
}

export default Hero;
