import React from 'react';
import { Link } from "react-router-dom";
import Analytics from "../analytics";
import './Start.css';

function Start() {
  return (
    <section className="Start">
      <div className="content">
        <div className="half bottom">
          <Link
            className="button"
            to="/contact"
            onClick={() => {
              Analytics.event("form_opened", {
                event_category: "form",
                event_label: "Contact Us"
              });
            }}>
            Contact Us
          </Link>
        </div>
        <div className="half top">
          <h1>Get started!</h1>
          <h2>Request a demo, sign up, or just ask a question</h2>
        </div>
      </div>
    </section>
  );
}

export default Start;
