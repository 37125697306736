import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, withRouter }
  from "react-router-dom";
import Bowser from "bowser";
import Analytics from "./analytics";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Home from "./Home/Home";
import Features from "./Features/Features";
import ServiceDriveDispatch from "./ServiceDriveDispatch/ServiceDriveDispatch";
import FinanceDispatch from "./FinanceDispatch/FinanceDispatch";
import UpSystem from "./UpSystem/UpSystem";
import ValetDispatch from "./ValetDispatch/ValetDispatch";
import WashRack from "./WashRack/WashRack";
import Solutions from "./Solutions/Solutions";
import Contact from "./Contact/Contact"
import './App.css';

// Detect browser
const browser = Bowser.getParser(window.navigator.userAgent);
const noPlaceholders = browser.satisfies({ "internet explorer": "<=9" });

function HistoryTracker(props) {
  // Scroll to top of page
  window.scroll({ top: 0, left: 0 });

  // Reset scroll thresholds tracking for analytics
  window.scrollThresholds = [];

  // Track page view
  Analytics.pageview(props.location.pathname)

  // Update page title ("/test" -> "Test", "/page_a" -> "Page A", etc.)
  let match = props.location.pathname.match(/.*\/(\w+)/);
  document.title = "LotSuite | " + ((match === null)
    ? "Automotive Up System & Dealer Tools"
    : match[1].replace(/_/g, ' ')
    // Capitalize first letter or "asm" (if followed by space or EOL)
    .replace(/\b(asm(?=\s|$)|\w)/g, (letter) => {
      return letter.toUpperCase();
    }));

  // Return empty component
  return "";
}

function App() {
  // Initialize analytics
  Analytics.initialize();

  // Track history
  const History = withRouter(HistoryTracker);

  // Track which scroll thresholds have been crossed
  window.scrollThresholds = [];
  const trackScroll = (position) => {
    // Record scrolling threshold (>0, 250, 500, 750...)
    if(window.scrollThresholds.indexOf(position) < 0) {
      // Prevent duplicate recording of this threshold
      window.scrollThresholds.push(position);
      // Record event
      Analytics.event("scroll", { event_label: position });
    }
  };
  // Track scrolling
  window.addEventListener('scroll', (event) => {
    let interval = 500;
    // Get page & window dimensions and current scroll position
    const pageHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;
    const currentPosition = document.documentElement.scrollTop;

    // Ignore if scrolling to top of page (e.g. changing pages)
    if(currentPosition === 0) return;

    // Round position to the interval, or `max` if at the end
    const prettyPosition = (currentPosition + windowHeight === pageHeight)
      ? "max"
      : (currentPosition < interval)
        ? ">0"
        : Math.floor(currentPosition / interval) * interval;

    // Track scroll
    trackScroll(prettyPosition);
  });

  // Class names
  let className = "App";
  if(noPlaceholders) className += " noPlaceholders";

  // Return webapp
  return (
    <Router>
      <div className={className}>

        <Switch>
          <Route exact path="/">
            <Home/>
          </Route>

          <Route exact path="/features">
            <Features/>
          </Route>

          <Route exact path="/asm_dispatch">
            <Redirect to="/service_drive_dispatch"/>
          </Route>

          <Route exact path="/finance_dispatch">
            <FinanceDispatch/>
          </Route>

          <Route exact path="/up_system">
            <UpSystem/>
          </Route>

          <Route exact path="/service_drive_dispatch">
            <ServiceDriveDispatch/>
          </Route>

          <Route exact path="/solutions">
            <Solutions/>
          </Route>

          <Route exact path="/valet_dispatch">
            <ValetDispatch/>
          </Route>

          <Route exact path="/wash_rack">
            <WashRack/>
          </Route>

          <Route exact path="/contact">
            <Contact/>
          </Route>

          <Redirect to="/"/>
        </Switch>

        <Header/>
        <Footer/>

      </div>
      <History/>
    </Router>
  );
}

export default App;
