import React from 'react';
import Analytics from "../analytics";
import './Demo.css';
const now = Math.floor(Date.now() / 1000);

class Tooltip extends React.Component {
  render() {
    return (
      <div
        name={this.props.id}
        className={
          "Tooltip "
          + this.props.align
          + ((this.props.show === this.props.id) ? " show" : "")
        }>

        <div
          className="indicator"
          onMouseEnter={() => {
            Analytics.event("hover", {
              event_category: "demo",
              event_label: this.props.id
            });
          }}></div>

        <div className="tip">
          {this.props.content}
        </div>

      </div>
    );
  }
}

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.clockInterval = null;
    this.timerMax = 1800;
    this.state = {
      timer: this.timerMax
    };
  }

  componentDidMount() {
    this.clockInterval = setInterval(() => {
      let timer = this.state.timer;
      timer = (timer < 1) ? this.timerMax : timer - 1;
      this.setState({ timer: timer });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.clockInterval);
  }

  formatTimestamp(value) {
    // Create new javascript date object
    let timestamp = new Date(value * 1000);

    // Convert timestamp to pretty string with options
    let pretty = timestamp.toLocaleString('en-US', {
      hour: "numeric",
      minute: "numeric"
    });

    // Return formatted date
    return pretty;
  }

  formatClock(value) {
    // Hours
    let hours = Math.floor(value / 3600)
      .toString().padStart(2, '0');
    // Minutes
    let minutes = Math.floor((value % 3600) / 60)
      .toString().padStart(2, '0');
    // Seconds
    let seconds = (value % 60)
      .toString().padStart(2, '0');
    // Combine into formatted value, hide hours if `00`
    return ((hours !== '00') ? hours + ":" : "")
      + minutes +":" + seconds;
  }

  render() {
    // Status
    let status, className = "";
    if(typeof this.props.status === "number") {
      status = (this.props.status === 1)
        ? this.formatClock(this.state.timer)
        : "#"+this.props.status.toString();
    } else {
      status = this.props.status;
      className = this.props.status;
    }

    // Return card
    return (
      <div className={"Card " + className}>

        <div className="field name">
          {this.props.name}
        </div>

        <div className="field status">
          <span>
            {status}
            {(status.indexOf(":") < 0) ? "" :
              (<Tooltip
                show={this.props.showTooltip}
                id="Timer"
                align="right"
                content="Auto-Rotation Keeps Salespeople Efficient"/>)
            }
            {(status.indexOf("#") < 0) ? "" :
              (<Tooltip
                show={this.props.showTooltip}
                id="Position"
                align="right"
                content="Mobile Notifications When You're Up Next"/>)
            }
          </span>
        </div>

        {(status !== "Hold") ? "" : (
          <div className="field message">
            <span>
              {this.props.message}
            </span>
          </div>
        )}

        <div className="field wait">
          {(status === "Customer") ? "With customer since "
            : (status === "Hold") ? "On hold since "
              : "Waiting since "}
          {this.formatTimestamp(now - this.props.time)}
          {(status === "Hold") ? (
            <Tooltip
              show={this.props.showTooltip}
              id="Hold"
              align="left bottom"
              content="Managers Can Hold & Override Employees"/>
          ) : "" }
        </div>

        {(status !== "Customer") ? "" : (
          <div className="field customer">
            <span>
              <span>
              Customer:
              <Tooltip
                show={this.props.showTooltip}
                id="CRM"
                align="left bottom"
                content="Customer Details Are Sent to Your CRM"/>
              </span>
              &nbsp;{this.props.customer_name}
            </span>
          </div>
        )}

        {(status !== "Customer") ? "" : (
          <div className="fauxMenu">
            <Tooltip
              show={this.props.showTooltip}
              id="Integrations"
              align="right"
              content="Send Deals to Finance Dispatch & Wash Rack"/>
          </div>
        )}

      </div>
    );
  }
}

class Demo extends React.Component {
  constructor(props) {
    super(props);
    this.tooltipInterval = null;
    this.tooltipTimer = 3;
    this.tooltipIds = [
      "Reporting",
      "Messaging",
      "Timer",
      "Position",
      "CRM",
      "Integrations",
      "Hold"
    ];
    this.state = {
      tooltipIndex: 0,
      timer: this.tooltipTimer
    };
  }

  componentDidMount() {
    this.tooltipInterval = setInterval(() => {
      // Set tooltipIndex
      let timer = this.state.timer;
      let tooltipIndex = this.state.tooltipIndex;
      tooltipIndex = (timer < 1)
        ? (this.tooltipIds.length >= tooltipIndex + 2)
          ? tooltipIndex + 1 : 0
        : tooltipIndex;
      // Update timer
      timer = (timer < 1) ? this.tooltipTimer : timer - 1;
      // Set state
      this.setState({
        timer: timer,
        tooltipIndex: tooltipIndex
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.tooltipInterval);
  }

  render() { return (
    <div
      className="Demo"
      onMouseEnter={() => {
        Analytics.event("hover", {
          event_category: "demo",
          event_label: "Preview"
        });
      }}>

      <div className="header">

        <div className="buttons left">
          <span className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>
          </span>
          <span className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464.9 128H344.1c-8.3 0-15.1 6.6-15.1 14.8s6.8 14.8 15.1 14.8h83.7l-138 142.2-85.9-84.1c-2.9-2.8-6.6-4.3-10.7-4.3-4 0-7.8 1.5-10.7 4.3L36.2 358.8c-1.9 1.9-4.2 5.2-4.2 10.7 0 4.1 1.4 7.5 4.2 10.2 2.9 2.8 6.6 4.3 10.7 4.3 4 0 7.8-1.5 10.7-4.3L193.2 247l85.9 84.1c2.9 2.8 6.6 4.3 10.7 4.3 4 0 7.8-1.5 10.7-4.3l149.4-151.9v81.7c0 8.1 6.8 14.8 15.1 14.8s15.1-6.6 15.1-14.8V142.8c-.1-8.2-6.9-14.8-15.2-14.8z"></path></svg>
            <Tooltip
              show={this.tooltipIds[this.state.tooltipIndex]}
              id="Reporting"
              align="left"
              content="Advanced Reporting with Time Tracking"/>
          </span>
        </div>

        <div className="title">
          <span className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zm143.3 263.9c-8.1-2.6-23.7-3.4-29.5-10.4-2.9-3.5-1-16.6-1-16.6 25.2-1.4 31-6.1 31-6.1 3.9-2.3 2.1-2.7.1-6.1-10.9-18.3-6-41.5-6.5-61.6-.4-16.7-4.8-35-20-44.4-7.6-4.7-19.7-6.9-31.4-6.9-9.5 0-18.7 1.4-25.3 4.1-42.4 17-17.4 73.2-31.9 105.4-2.5 5.4-6.1 7.3.2 10.5 0 0 7.5 4.4 28.9 6.3 0 0 .3 12.6 0 13.9-1.1 4.9-11.8 8.3-15.8 9.4-2.7.7-8.7 2.5-16.4 5.7-3.7 1.5-3.1 6.9.8 7.6 3.9.7 8.1 1.7 11.5 2.9 0 0 26.4 6 46 25 13.2 12.7 18.1 27.6 20 44.6.5 4.5-1.7 8.9-5.5 11.5l-5.7 3.6c-1.9 1.1-4.2-.2-4.3-2.4 0-29.5-17.8-55.5-45.8-65.2-13.5-4.7-28.1-5-41.6-9.7-4.1-1.4-12.2-3.1-13.9-7.8-1.6-4.6-1.6-10-1.9-14.8-.2-3.8-.3-7.6-.3-11.4 0-2.5 6.4-7.8 7.8-10.1 5.4-9 5.9-21.1 6.9-31.3 8.7 2.4 9.8-13.7 11.3-18.6 1.1-3.4 5-20.9-2.6-23.6 2.5-4.4 3.5-9.8 4.2-14.7 2-12.8 2.8-26.8-1.1-39.3-8.1-26-33-40.6-59.3-41.4-26.7-.9-53.5 11.9-63.5 37.8-4.8 12.6-4.4 26.3-2.8 39.5.7 6 1.7 12.7 4.7 18.1-6.4 2.8-4.5 17.7-3.4 21.3 1.6 5.1 3 23.4 12.1 20.9.8 8.1 1.7 16.4 3.9 24.3 1.5 5.3 4.6 9.8 8.2 13.9 1.8 2 2.7 2.2 2.6 4.8-.1 7.8.1 16.2-1.9 23.8s-18.7 10.8-25.4 12.2c-14.8 3-28.6 4.7-41.4 11.5C84.8 320.6 76 289 76 256c0-47.9 19.2-94 53.2-127.7C162.7 95 207.1 76.4 254.3 76c47.6-.4 93.7 18.1 127.8 51.5 34.8 34.1 54 79.9 54 128.5 0 24.9-5 48.9-14.5 71.1-5.6-7-13.4-12.3-22.3-15.2z"></path></svg>
          </span>
          <span>
            Up System
          </span>
        </div>

        <div className="buttons right">
          <span className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M460.6 147.3L353 256.9c-.8.8-.8 2 0 2.8l75.3 80.2c5.1 5.1 5.1 13.3 0 18.4-2.5 2.5-5.9 3.8-9.2 3.8s-6.7-1.3-9.2-3.8l-75-79.9c-.8-.8-2.1-.8-2.9 0L313.7 297c-15.3 15.5-35.6 24.1-57.4 24.2-22.1.1-43.1-9.2-58.6-24.9l-17.6-17.9c-.8-.8-2.1-.8-2.9 0l-75 79.9c-2.5 2.5-5.9 3.8-9.2 3.8s-6.7-1.3-9.2-3.8c-5.1-5.1-5.1-13.3 0-18.4l75.3-80.2c.7-.8.7-2 0-2.8L51.4 147.3c-1.3-1.3-3.4-.4-3.4 1.4V368c0 17.6 14.4 32 32 32h352c17.6 0 32-14.4 32-32V148.7c0-1.8-2.2-2.6-3.4-1.4z"></path><path d="M256 295.1c14.8 0 28.7-5.8 39.1-16.4L452 119c-5.5-4.4-12.3-7-19.8-7H79.9c-7.5 0-14.4 2.6-19.8 7L217 278.7c10.3 10.5 24.2 16.4 39 16.4z"></path></svg>
            <Tooltip
              show={this.tooltipIds[this.state.tooltipIndex]}
              id="Messaging"
              align="right"
              content="Built-In Messaging and Silent Paging"/>
          </span>
          <span className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M304 256c52.805 0 96-43.201 96-96s-43.195-96-96-96-96 43.201-96 96 43.195 96 96 96zm0 48c-63.598 0-192 32.402-192 96v48h384v-48c0-63.598-128.402-96-192-96zM112 224v-64H80v64H16v32h64v64h32v-64h64v-32h-64z"></path></svg>
          </span>
        </div>

      </div>

      <Card
        showTooltip={this.tooltipIds[this.state.tooltipIndex]}
        name="Jim Halpert"
        time={2000}
        status={1}/>
      <Card
        showTooltip={this.tooltipIds[this.state.tooltipIndex]}
        name="Pam Beesly"
        time={1500}
        status={2}/>
      <Card
        showTooltip={this.tooltipIds[this.state.tooltipIndex]}
        name="Michael Scott"
        time={3000}
        customer_name="Bob Vance"
        status="Customer"/>
      <Card
        showTooltip={this.tooltipIds[this.state.tooltipIndex]}
        name="Ryan Howard"
        time={4000}
        message="Out to lunch"
        status="Hold"/>

      <div className="instructions">
        {("ontouchstart" in document.documentElement)
          ? "Tap preview to show features"
          : "Hover over preview to show features"}
      </div>

    </div>
  ) };
}

export default Demo;
