import React from 'react';
import { Link } from "react-router-dom";
import './Features.css';

function Features() {
  return (
    <div className="Features">
      <div className="content">
        <h1>Fix your Finance Department</h1>
        <h2>Efficiently match finance managers to deals</h2>
        <div className="item">
          <h2>Distribute Deals</h2>
          Assign deals to managers as soon as salespeople are finished
        </div>
        <div className="item new">
          <h2>Sales Integration</h2>
          Finance deals can be sent from the <Link to="/up_system">Up System</Link> with one click
        </div>
        <div className="item">
          <h2>Blind Assignment</h2>
          Hide customer and deal details to eliminate cherry-picking
        </div>
        <div className="showMoreItems">
          <div
            className="button"
            onClick={(e) => e.target.parentNode.classList.add("show")}>
            Show More
          </div>
          <div className="fakeItem"/>
        </div>
        <div className="item new">
          <h2>Time Tracking</h2>
          Time the entire sales process for each deal from start to finish
        </div>
        <div className="item">
          <h2>Real-Time Monitoring</h2>
          Salespeople can monitor their deals and receive notifications
        </div>
        <div className="item">
          <h2>Performance Tracking</h2>
          Monitor the productivity of each finance manager in real-time
        </div>
        <div className="item">
          <h2>Advanced Reporting</h2>
          View in real-time or export by day, week, month, or year
        </div>
        <div className="item">
          <h2>Communicate Issues</h2>
          Message the salesperson for any deal with two clicks
        </div>
        <div className="item">
          <h2>Status Updates</h2>
          Salesperson is notified when the deal is claimed or completed
        </div>
        <div className="item">
          <h2>Process Transparency</h2>
          Employees and managers can see when each vehicle is ready
        </div>
        <div className="item">
          <h2>Improve CSI</h2>
          Speed up the sales process and keep customers happy
        </div>
        <div className="item">
          <h2>Increase Capacity</h2>
          Get more vehicles through your finance department in less time
        </div>
        <div className="item">
          <h2>100% Customizable</h2>
          Customize your workflows, buttons, integrations, and more
        </div>
        <div className="item">
          <h2>Works Where You Do</h2>
          Access LotSuite from your desktop, smartphone, or tablet
        </div>
        <Link
          to="/features"
          className="item">
          <div className="center">
            <h2>View More</h2>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Features;
