import React from 'react';
import Background from '../Home/Background';
import Features from './Features'
import Start from '../Home/Start';
import './WashRack.css';


function Hero() {
  return (
    <div className="Hero">
      <Background/>
      <div className="content">
        <h1>Wash Rack</h1>
        <h3>Monitor and manage vehicles being washed</h3>
      </div>
    </div>
  );
}

function WashRack() {
  return (
    <div className="DetailsPage WashRack">
      <Hero/>
      <Features/>
      <Start/>
    </div>
  );
}

export default WashRack;
