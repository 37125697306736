import React from 'react';
import { Link } from "react-router-dom";
import './Features.css';

function Features() {
  return (
    <div className="Features">
      <div className="content">
        <h1>Revamp your Wash Rack</h1>
        <h2>Check the progress of a wash from anywhere</h2>
        <div className="item">
          <h2>Status Updates</h2>
          Be notified when the wash has started or the vehicle is ready
        </div>
        <div className="item new">
          <h2>Time Tracking</h2>
          Measures how long employees take to wash each car
        </div>
        <div className="item">
          <h2>More Sales, Fewer Steps</h2>
          Stop wasting time walking to check if a car has been washed
        </div>
        <div className="showMoreItems">
          <div
            className="button"
            onClick={(e) => e.target.parentNode.classList.add("show")}>
            Show More
          </div>
          <div className="fakeItem"/>
        </div>
        <div className="item">
          <h2>Communicate Issues</h2>
          Washer can send alerts about missing floor mats, delays, etc.
        </div>
        <div className="item">
          <h2>Check Progress</h2>
          Check the progress of a wash instantly from anywhere
        </div>
        <div className="item">
          <h2>Sales and Service</h2>
          Works with salespeople, service writers, and anyone else
        </div>
        <div className="item new">
          <h2>Sales Integration</h2>
          Sold cars from the <Link to="/up_system">Up System</Link> are sent for a pre-delivery wash
        </div>
        <div className="item">
          <h2>Performance Tracking</h2>
          Monitor employees to find underperformers in real-time
        </div>
        <div className="item">
          <h2>Advanced Reporting</h2>
          View in real-time or export by day, week, month, or year
        </div>
        <div className="item">
          <h2>Barcode Scanner</h2>
          Scan barcodes on vehicles or paperwork instead of typing
        </div>
        <div className="item">
          <h2>Automated Integrations</h2>
          Send cars to the Wash Rack from any other LotSuite tool
        </div>
        <div className="item">
          <h2>Improve CSI</h2>
          Cut wait times by knowing as soon as a car is ready for pickup
        </div>
        <div className="item">
          <h2>100% Customizable</h2>
          Customize your workflows, buttons, integrations, and more
        </div>
        <div className="item">
          <h2>Works Where You Do</h2>
          Access LotSuite from your desktop, smartphone, or tablet
        </div>
        <Link
          to="/features"
          className="item">
          <div className="center">
            <h2>View More</h2>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Features;
