import React from 'react';
import { Link, NavLink } from "react-router-dom";
import Analytics from "../analytics";
import './Header.css';
import { ReactComponent as LotSuiteIcon } from '../SVG/lotsuite.svg';
import { ReactComponent as MenuIcon } from '../SVG/menu.svg';
import { ReactComponent as CloseIcon } from '../SVG/close.svg';

class PrintViewLinks extends React.Component {
  render() {
    return (
      <div className="PrintViewLinks">
        <a href="./">https://lotsuite.com</a>
        <a href="./">@lotsuite.com</a>
      </div>
    );
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showMenu: false };
  }

  toggleMenu(show) {
    this.setState({
      showMenu: (typeof show === "boolean")
        ? show
        : !this.state.showMenu
    });
  }

  render() {
    return [
      <div className="Header" key="Header">
        <div className="content">
          <Link to="/" className="logo">
            <LotSuiteIcon className="icon"/>
            <span className="title">
              LotSuite
            </span>
          </Link>

          <PrintViewLinks/>

          <MenuIcon
            className="open"
            onClick={() => this.toggleMenu(true)}/>

          <div className={"menu" + ((this.state.showMenu) ? " show" : "")}>
            <CloseIcon
              className="close"
              onClick={() => this.toggleMenu(false)}/>

            <div
              className="links"
              onClick={() => this.toggleMenu(false)}>
              <NavLink exact={true} to="/">
                Overview
                <span>How LotSuite can help you</span>
              </NavLink>
              <NavLink exact={true} to="/features">
                Features
                <span>Learn more about LotSuite</span>
              </NavLink>
              <NavLink exact={true} to="/up_system">
                Up System
                <span>Explore the flagship tool</span>
              </NavLink>
              <NavLink exact={true} to="/solutions">
                Solutions
                <span>View all the tools in LotSuite</span>
              </NavLink>
              <NavLink exact={true} to="/contact">
                Contact
                <span>Get in touch with us</span>
              </NavLink>
            </div>

            <a
              className="login"
              onClick={() => {
                // Hide menu
                this.toggleMenu(false);
                // Record event
                Analytics.event("log_in", {
                  event_category: "link",
                  event_label: "Log In (Header)"
                });
              }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://app.lotsuite.com">
              Log In
            </a>
          </div>
        </div>
      </div>
    ];
  }
}

export default Header;
