import React from 'react';
import { Link } from "react-router-dom";
import './Features.css';

function Features() {
  return (
    <div className="Features">
      <div className="content">
        <h1>Perfect your Service Pickups</h1>
        <h2>Automate your service vehicle pickup process</h2>
        <div className="item">
          <h2>Dispatch Vehicles</h2>
          Instruct valets/porters which vehicles need to be retrieved
        </div>
        <div className="item new">
          <h2>Time Tracking</h2>
          Monitor how long each valet takes to find and deliver cars
        </div>
        <div className="item">
          <h2>Replace Radio Callouts</h2>
          See a digital list of vehicles to be picked up, instead of listening
        </div>
        <div className="showMoreItems">
          <div
            className="button"
            onClick={(e) => e.target.parentNode.classList.add("show")}>
            Show More
          </div>
          <div className="fakeItem"/>
        </div>
        <div className="item new">
          <h2>Automatic Delivery</h2>
          Send cars from the <Link to="/wash_rack">Wash Rack</Link> straight to the delivery queue
        </div>
        <div className="item">
          <h2>Real-Time Monitoring</h2>
          See when each vehicle has been claimed, found, and delivered
        </div>
        <div className="item">
          <h2>Advanced Reporting</h2>
          View in real-time or export by day, week, month, or year
        </div>
        <div className="item">
          <h2>Priority Vehicles</h2>
          Prioritize VIP vehicles to be picked up before others
        </div>
        <div className="item">
          <h2>Process Transparency</h2>
          Cashiers, writers, and valets can all see when a vehicle is ready
        </div>
        <div className="item">
          <h2>Barcode Scanner</h2>
          Add vehicles by scanning the repair order or by manual entry
        </div>
        <div className="item">
          <h2>Reduce Wait Times</h2>
          Shorten the time customers have to wait to pick up their car
        </div>
        <div className="item">
          <h2>Improve CSI</h2>
          No more missed vehicles and unhappy, waiting customers
        </div>
        <div className="item">
          <h2>Increase Capacity</h2>
          Get more vehicles through your service drive in less time
        </div>
        <div className="item">
          <h2>100% Customizable</h2>
          Customize your workflows, buttons, integrations, and more
        </div>
        <div className="item">
          <h2>Works Where You Do</h2>
          Access LotSuite from your desktop, smartphone, or tablet
        </div>
        <Link
          to="/features"
          className="item">
          <div className="center">
            <h2>View More</h2>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Features;
