import React from 'react';
import { Link } from "react-router-dom";
import './Overview.css';
import { ReactComponent as Concave } from '../SVG/concave.svg';

function Overview() {
  return (
    <section className="Overview">
      <Concave className="concave top"/>
      <div className="content">
        <h1>What can LotSuite do?</h1>
        <div className="item">
          <h2>Assign Ups</h2>
          Manage your sales team with the most advanced&nbsp;
          <Link to="/up_system">Up System</Link>
        </div>
        <div className="item">
          <h2>Increase Leads</h2>
          Integrates with your CRM to create leads automatically
        </div>
        <div className="item">
          <h2>Speed Up Sales</h2>
          Get customers out the door faster with our&nbsp;
          <Link to="/finance_dispatch">Finance Dispatch</Link>
        </div>
        <div className="showMoreItems">
          <div
            className="button"
            onClick={(e) => e.target.parentNode.classList.add("show")}>
            Show More
          </div>
          <div className="fakeItem"/>
        </div>
        <div className="item">
          <h2>Track Users</h2>
          Monitor employees with real-time &amp; historical reporting
        </div>
        <div className="item">
          <h2>Improve Efficiency</h2>
          Instant alerts when a car is ready with our&nbsp;
          <Link to="/wash_rack">Wash Rack</Link> tool
        </div>
        <div className="item">
          <h2>Expedited Service</h2>
          Speed up vehicle delivery with our&nbsp;
          <Link to="/valet_dispatch">Valet Dispatch</Link> solution
        </div>
      </div>
      <Concave className="concave bottom"/>
    </section>
  );
}

export default Overview;
