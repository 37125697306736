import React from 'react';
import { Link } from "react-router-dom";
import './Features.css';

function Features() {
  return (
    <div className="Features">
      <div className="content">
        <h1>Optimize the Service Drive</h1>
        <h2>Match customers to available service writers</h2>
        <div className="item">
          <h2>Manage Customers</h2>
          Dispatch customers to service writers fairly and transparently
        </div>
        <div className="item">
          <h2>Increase Capacity</h2>
          Get more vehicles through your service drive in less time
        </div>
        <div className="item new">
          <h2>Valet Integration</h2>
          One click to send a car to the <Link to="/valet_dispatch">Valet Dispatch</Link> for delivery
        </div>
        <div className="showMoreItems">
          <div
            className="button"
            onClick={(e) => e.target.parentNode.classList.add("show")}>
            Show More
          </div>
          <div className="fakeItem"/>
        </div>
        <div className="item new">
          <h2>Time Tracking</h2>
          Measure customer's time in the service drive from start to finish
        </div>
        <div className="item">
          <h2>Round Robin or Flex</h2>
          You decide the order to assign customers to service writers
        </div>
        <div className="item new">
          <h2>Send to Sales</h2>
          Integrates with Up System to page all available salespeople
        </div>
        <div className="item">
          <h2>Improve CSI</h2>
          Serve customers faster with whole-team transparency
        </div>
        <div className="item">
          <h2>Prevent Conflict</h2>
          Ensure customers are evenly distributed to each employee
        </div>
        <div className="item">
          <h2>LPR &amp; RFID Integration</h2>
          Assign customers to a waiting employee before they park
        </div>
        <div className="item">
          <h2>Paging System</h2>
          Message your whole team or a specific employee
        </div>
        <div className="item">
          <h2>Mobile Alerts</h2>
          Receive notifications when a customer needs assistance
        </div>
        <div className="item">
          <h2>Advanced Reporting</h2>
          View in real-time or export by day, week, month, or year
        </div>
        <div className="item">
          <h2>100% Customizable</h2>
          Customize your workflows, buttons, integrations, and more
        </div>
        <div className="item">
          <h2>Works Where You Do</h2>
          Access LotSuite from your desktop, smartphone, or tablet
        </div>
        <Link
          to="/features"
          className="item">
          <div className="center">
            <h2>View More</h2>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Features;
