import React from 'react';
import Hero from './Hero';
import Overview from './Overview';
import SolutionsSection from './SolutionsSection';
import Logos from './Logos';
import Start from './Start';
import './Home.css';

function Home() {
  return (
    <div className="Home">
      <Hero/>
      <Overview/>
      <Logos/>
      <SolutionsSection/>
      <Start/>
    </div>
  );
}

export default Home;
