import React from 'react';
import Start from '../Home/Start';
import './Features.css';
import '../Home/Start.css';

function Features() {
  return (
    <div className="Features darkHeader">
      <div className="content">
        <h1>Key Features</h1>
        <h2>Explore LotSuite's most popular features</h2>
        <div className="item">
          <h2>Advanced Reporting</h2>
          View in real-time or export by day, week, month, or year
        </div>
        <div className="item new">
          <h2>Time Tracking</h2>
          See how long each step of the sales or service process takes
        </div>
        <div className="item">
          <h2>Mobile Notifications</h2>
          Receive alerts instantly on any of your devices
        </div>
        <div className="showMoreItems">
          <div
            className="button"
            onClick={(e) => e.target.parentNode.classList.add("show")}>
            Show More
          </div>
          <div className="fakeItem"/>
        </div>
        <div className="item new">
          <h2>CRM Integration</h2>
          Push customer information from LotSuite into your CRM
        </div>
        <div className="item">
          <h2>Paging System</h2>
          Message your whole team or a specific employee
        </div>
        <div className="item">
          <h2>Auto-Rotation</h2>
          Rotate the salespeople on your up list automatically
        </div>
        <div className="item">
          <h2>Access Control</h2>
          Control access for each employee &amp; set permissions
        </div>
        <div className="item">
          <h2>Performance Tracking</h2>
          Monitor employees to find underperformers in real-time
        </div>
        <div className="item new">
          <h2>Data Archive</h2>
          Access and export your recent and historical data any time
        </div>
        <div className="item">
          <h2>Barcode Scanner</h2>
          Scan barcodes on vehicles or paperwork instead of typing
        </div>
        <div className="item">
          <h2>100% Customizable</h2>
          Customize your workflows, buttons, integrations, and more
        </div>
        <div className="item">
          <h2>Works Where You Do</h2>
          Access LotSuite from your desktop, smartphone, or tablet
        </div>
      </div>
      <Start/>
    </div>
  );
}

export default Features;
